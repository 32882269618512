.bg-blue {
  background-color: #2196f3;
}

#url {
  overflow-wrap: break-word;
}

#body {
  width: 100vw;
}

.in-msg-img {
  width: 70%;
}

.fit-img {
  width: 100%;
  height: 100%;
}
